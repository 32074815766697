.root {
  bottom: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  padding: 16px 0 0 !important;
  position: absolute;
  top: 35px;
  width: 256px;
}

.a {
  justify-content: flex-start !important;
}

.footer {
  padding: 0 16px 6px;
}

.menu {
  flex: 1;
}

.menu > div > a {
  border-radius: unset;
  min-height: 48px !important;
  padding-top: 16px;
  padding-bottom: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.menu > div > a > span:first-of-type{
  text-align: left;
  padding-left: 6.4px; /*Default padding set 10px to point ICON*/
  padding-right: 11px;
}

.section {
  display: flex;
  height: 48px;
  padding: 6px 16px;
}

.section > h6 {
  color: #8A9BA8;
  flex-grow: 1;
  font-weight: 500;
  text-transform: uppercase;
  margin: 10px 8px;
}

.section > button {
  margin: auto 3px;
}
