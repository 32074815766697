@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  width: .5em;
  height: .5em;
}

*::-webkit-scrollbar-track {
  background-color: #A7B6C233;
}

*::-webkit-scrollbar-thumb {
  background-color: #5C708069;
}

.section-title {
  color: #5f6b7c;
  font-size: 0.9rem !important;
  font-family: 'Roboto';
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.bp4-dark .section-title {
  color: #abb3bf;
}

.form-inline {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.section-title:not(:first-child) {
  margin-top: 32px;
}
