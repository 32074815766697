.inputArray {
  margin-top: 48px;
}

.inputArray > div:first-child {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

.inputArray > div:first-child > h5 {
  margin: 0;
  line-height: 1.9rem !important;
}

.inputArray > div:first-child > h5 > span:first-child {
  padding-top: 2px;
}

.inputArray > div:first-child > h5 > span:last-child {
  margin-left: 8px;
  padding: 2px 8px 1px;
}

.inputArray .list {
  padding: 0;
  margin-bottom: 8px;
}

.inputArray .list > div {
  display: grid;
  grid-template-columns: 1fr auto;
}

.inputArray .list > div > div {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
}

.inputArray .list > div > div:not(:first-child) {
  border-left: 1px solid #C5CBD3;
}

:global(.bp4-dark) .inputArray .list > div > div:not(:first-child) {
  border-left: 1px solid #5F6B7C;
}
