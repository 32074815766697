.root {
  width: calc(100% + 32px);
  height: 100%;
  margin: -16px;
}

.container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-width: 20rem;
  max-width: 1024px;
  margin: 32px auto 0;
  padding-bottom: 3.2rem;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  width: auto;
}

.container > h3 {
  align-items: baseline;
  display: flex;
}

.container > h3 > span {
  flex: 1;
}

.container > h3 > button {
  margin-bottom: 0.2rem;
  margin-right: 0.5rem;
}

.container > h3 > button:nth-child(2) {
  float: right;
}

.divider {
  margin: 0 -20px 20px;
}
