.root {
  border: 1pt solid #000;
  margin: 16pt;
  padding: 6pt 16pt;
}

.root h2 {
  text-align: center;
  margin-top: 4pt;
  margin-bottom: 4pt;
}

.root table {
  width: 100%;
}

.kop {
  border-bottom: 1pt solid #000;
  padding-bottom: 4pt;
  margin-left: -16pt;
  margin-right: -16pt;
}

.kop * {
  margin-top: 0;
  margin-bottom: 1pt;
  text-align: center;
  width: 100%;
}

.kop h2 {
  margin-top: 0;
  margin-bottom: 1pt;
}

.kop p {
  margin-top: 2pt;
}

.gb {
  border-bottom: 1.5pt solid #000;
  margin-top: 1pt;
  margin-left: -16pt;
  margin-right: -16pt;
}

.nomor {
  border-bottom: 1pt solid #000;
  text-align: right;
  margin-top: 20pt;
  margin-bottom: 10pt;
  margin-left: -16pt;
  margin-right: -16pt;
  padding-bottom: 10pt;
  padding-right: 16pt;
}

.nomor > div {
  margin-bottom: 4pt;
}

.form > div {
  display: grid;
  grid-template-columns: 100pt 1fr;
  margin-bottom: 4pt;
}

.batas {
  margin-top: 24pt;
}

.garis {
  border-bottom: 1pt solid #000;
  margin-top: 6pt;
  margin-bottom: 6pt;
  margin-left: -16pt;
  margin-right: -16pt;
}

.footer {
  margin-left: 50%;
  margin-top: 24pt;
  margin-bottom: 32pt;
  text-align: center;
}

.footer > div {
  margin-bottom: 0;
}
