.root {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: min-content;
  row-gap: 0.5rem;
  width: 100%;
}

.root > div {
  margin: 0;
}

.root > table {
  grid-column: 1/3;
  width: 100%;
}

.root > table > tbody > tr > td {
  vertical-align: middle;
}

.empty {
  text-align: center !important;
}

.empty > img {
  margin: 1rem auto;
  width: 12rem;
}

.empty > p {
  opacity: 0.7;
}

.input {
  width: 6rem;
}

.pagination {
  grid-column: 1/3;
  justify-self: center;
  margin-top: 1rem !important;
}

